import React, {useState, useRef, useEffect } from 'react';
import {TextProductionView,FrameProductionView,HoleProductionView,SymbolProductionView} from '../productionView';
import {api} from 'modules/api';
import $ from 'jquery';
import DOMPurify from 'dompurify';
import {round} from 'utils/mathFunctions';
import {colorCodes} from 'config';
import {objectDatadump} from 'utils/objectDatadump';
import { convert, downloadFile } from 'utils/objectsToCVS';
import QrCodeBlock from 'svg/blocks/qrcodeBlock';
import PrintSorting from 'components/printSorting';

var lang = require('lang/se.json');
const blockTypes = {
  TextBlock: TextProductionView,
  FrameBlock: FrameProductionView,
  HoleBlock: HoleProductionView,
  SymbolBlock: SymbolProductionView
}

export const ProductionSortProduct = (props) => {

  const orderRow = props.orderRow;

  const object = JSON.parse(orderRow.object);

//  const measure = ( typeof object.diameter !== 'undefined' && object.diameter > 0 ? object.diameter : object.width+'x'+object.height );
//  const attach_method =  ( orderRow.field3 !== null && orderRow.field3.length > 0 ? orderRow.field3.replace('<br/>','') : 'Inga hål, ingen tejp' );
  const holes = object.hole_quantity;
  let template = object.name;
  if( typeof template === 'undefined' || template === null || template.length === 0 )
    template = orderRow.field1;

  let blockQty = 0;
  if( typeof object.blocks !== 'undefined' )
    blockQty = object.blocks.length;

  const holeSize = ( ( object.standard_holes === true || object.standard_holes === "true" ) ? '(&oslash;: '+object.hole_size+'mm, dist: '+object.hole_distance+'mm)' : '');
  const tape = ( (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" && object.tape !== 'no_tape' ) ? lang[object.tape] : '' );

  return (
    <div className="hashedProd" style={{ marginBottom:40 }}>
      <div className="production-properties">
        <div style={{ marginBottom:10 }}>
          <div className="production-property">Produkttyp: </div>
          <span>
            <b>{ lang[object.translation_tag] } </b>
          </span>
        </div>
        <table style={{ width:'100%' }}>
          <tbody>
          <tr>
            <td style={{ width: 70 }}>
              <div>Mall:</div>
              <div>Material:</div>
              { object.product_type === 'WireMarker' && <div>Typ:</div> }
              {
                object.product_type === 'Rectangular' &&
                <div>Form:</div>
              }
              <div>Storlek:</div>
              {
                (object.hole_quantity > 0 && object.product_type === 'Rectangular') &&
                <div>Hål:</div>
              }

              { ( typeof object.stripe_holes !== 'undefined' && ( object.stripe_holes === true || object.stripe_holes === "true" ) ) && <div>{lang.label_stripe_holes}</div> }
              { (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" && object.tape !== 'no_tape' ) && <div>Tejp:</div> }

            </td>
            <td style={{ width: 250 }}>
              <div><b>{ template  }</b></div>
              {
                typeof object.material !== 'undefined'  &&
                <div><b>{ lang.materials[object.material] } ({object.color})</b> </div>
              }
              { object.product_type === 'WireMarker' && <div><b>{ object.size_type }</b></div> }
              {
                object.product_type === 'Rectangular' &&
                <div><b>{ lang.shapes[object.shape]  }</b></div>
              }
              <div><b>{ orderRow.field2 }</b></div>
              <div>
              {
                (object.hole_quantity > 0 && object.product_type === 'Rectangular') &&
                <React.Fragment>
                <span><b>{ holes > 0 && holes }</b></span>
                <b>{ ( (object.standard_holes === true || object.standard_holes === "true" ) && object.hole_quantity > 0 ) && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(holeSize) }} />}</b>
                </React.Fragment>
              }
              </div>

              { ( typeof object.stripe_holes !== 'undefined' && ( object.stripe_holes === true || object.stripe_holes === "true" ) ) && <div><b>{lang.boolean[object.stripe_holes]}</b></div> }
              { (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" ) && <div><b>{ tape }</b></div> }

            </td>
            <td>
              <div className="m-l-10"><PrintSorting /></div>
            </td>
          </tr>
          </tbody>
        </table>
        <table>
        <tbody>
          <tr style={{ marginTop:5, fontSize:15 }}>
            <td style={{ width: 200 }}>
              <div style={{ marginBottom:10 }}><b>Antal block: {blockQty}</b></div>
            </td>
            <td >
                <div style={{ marginBottom:10 }}><b>Antal skyltar: { props.quantity }</b></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      {
        typeof object.other_info !== 'undefined' &&
        <div>
          { lang.label_other_info }: <b>{ object.other_info.replace('<br/>','. ') }</b>
        </div>

      }
      <div className="production-blocks" style={{ clear:'both'}}>

        <ProductionBlockContent object={object} />
      </div>
      { props.children }
    </div>
  );
};

export const ProductionSortSignMarking = (props) => {

    const orderRow = props.orderRow;
  
    const object = JSON.parse(orderRow.object);
    
  
  //  const measure = ( typeof object.diameter !== 'undefined' && object.diameter > 0 ? object.diameter : object.width+'x'+object.height );
  //  const attach_method =  ( orderRow.field3 !== null && orderRow.field3.length > 0 ? orderRow.field3.replace('<br/>','') : 'Inga hål, ingen tejp' );
    const holes = object.hole_quantity;
    let template = object.name;
    if( typeof template === 'undefined' || template === null || template.length === 0 )
      template = orderRow.field1;
  
    let blockQty = 0;
    if( typeof object.blocks !== 'undefined' )
      blockQty = object.blocks.length;
  
    const holeSize = ( ( object.standard_holes === true || object.standard_holes === "true" ) ? '(&oslash;: '+object.hole_size+'mm, dist: '+object.hole_distance+'mm)' : '');
    const tape = ( (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" && object.tape !== 'no_tape' ) ? lang[object.tape] : '' );
  
    return (
      <div className="hashedProd" style={{ marginBottom:40 }}>
        <div className="production-properties">
          <div style={{ marginBottom:10 }}>
            <div className="production-property">Produkttyp: </div>
            <span>
              <b>{ lang[object.translation_tag] } </b>
            </span>
          </div>
          <table style={{ width:'100%' }}>
            <tbody>
            <tr>
              <td style={{ width: 70 }}>
                <div>Mall:</div>
                <div>Material:</div>
                { object.product_type === 'WireMarker' && <div>Typ:</div> }
                {
                  object.product_type === 'Rectangular' &&
                  <div>Form:</div>
                }
                <div>Storlek:</div>
                {
                  (object.hole_quantity > 0 && object.product_type === 'Rectangular') &&
                  <div>Hål:</div>
                }
  
                { ( typeof object.stripe_holes !== 'undefined' && ( object.stripe_holes === true || object.stripe_holes === "true" ) ) && <div>{lang.label_stripe_holes}</div> }
                { (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" && object.tape !== 'no_tape' ) && <div>Tejp:</div> }
  
              </td>
              <td style={{ width: 250 }}>
                <div><b>{ template  }</b></div>
                {
                  typeof object.material !== 'undefined'  &&
                  <div><b>{ lang.materials[object.material] } ({object.color})</b> </div>
                }
                { object.product_type === 'WireMarker' && <div><b>{ object.size_type }</b></div> }
                {
                  object.product_type === 'Rectangular' &&
                  <div><b>{ lang.shapes[object.shape]  }</b></div>
                }
                <div><b>{ orderRow.field2 }</b></div>
                <div>
                {
                  (object.hole_quantity > 0 && object.product_type === 'Rectangular') &&
                  <React.Fragment>
                  <span><b>{ holes > 0 && holes }</b></span>
                  <b>{ ( (object.standard_holes === true || object.standard_holes === "true" ) && object.hole_quantity > 0 ) && <span dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(holeSize) }} />}</b>
                  </React.Fragment>
                }
                </div>
  
                { ( typeof object.stripe_holes !== 'undefined' && ( object.stripe_holes === true || object.stripe_holes === "true" ) ) && <div><b>{lang.boolean[object.stripe_holes]}</b></div> }
                { (typeof object.tape !== 'undefined' && object.tape !== false && object.tape !== "false" ) && <div><b>{ tape }</b></div> }
  
              </td>
              <td>
                <div className="m-l-10"><PrintSorting /></div>
              </td>
            </tr>
            </tbody>
          </table>
          <table>
          <tbody>
            <tr style={{ marginTop:5, fontSize:15 }}>
              <td style={{ width: 200 }}>
                <div style={{ marginBottom:10 }}><b>Antal block: {blockQty}</b></div>
              </td>
              <td >
                  <div style={{ marginBottom:10 }}><b>Antal skyltar: { props.quantity }</b></div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        {
          typeof object.other_info !== 'undefined' &&
          <div>
            { lang.label_other_info }: <b>{ object.other_info.replace('<br/>','. ') }</b>
          </div>
  
        }
        <div className="production-blocks" style={{ clear:'both'}}>
  
          
        </div>
        { props.children }
      </div>
    );
  };

export const ProductionSortArticle = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);
//  let rows = JSON.parse(object.textrows);

  return (
    <div className="hashedProd" style={{ marginBottom:40 }}>
    {
      ( object !== undefined && object !== null ) &&
      <div className="production-properties">
        <h5>Produkttyp: {lang[object.entity] } ({orderRow.field1}) </h5>
        <div>
          <div><img style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } alt={ object.imagepath }/></div>
          <div><b>{ orderRow.field1 }</b> ({ lang[object.entity] })</div>
          <div><b>{ lang['label_description'] }: </b>{ object.info }</div>
          <div><b>{ lang['label_quantity'] }:</b> { props.quantity }</div>
        </div>
        { props.children }
      </div>

    }
    {
      object === null &&
      <div> { "{{{ DATAFEL " } <br/> { JSON.stringify( objectDatadump( orderRow ) ) } <br/> { ' }}}' }</div>
    }
    </div>
  );
}

export const ProductionSortTerminalMarking = (props) => {

    const orderRow = props.orderRow;
    const object = JSON.parse(orderRow.object);
    const cardQuantity = props.items.length;
    const selection = useRef(false);
    let [isSelected, setIsSelected] = useState(false);
    
    const prepareRows = ( productRows, index ) => {
         productRows.forEach( row => {
            delete row.selected;
        })
       // downloadFile( productRows, `${orderRow.oid}_${object.product_name}_${index}` );
    }

    const onSelectText = ( e ) => {
        let text =  selection.current.querySelectorAll('.copy-field');
        let selectedText = '';
        const rows = Object.values(text).map( (span) => {
            selectedText += span.innerText+'\n';
        });
    
        

        navigator.clipboard.writeText(selectedText)
        .catch(err => {
            console.error('Error copying text to clipboard:', err);
        });
        isSelected === true ? setIsSelected( false ) : setIsSelected( true );
       
    };

  
    return (
      <div className="hashedProd" style={{ marginBottom:40 }}>
        <div className="production-properties">
          <div><b>{ orderRow.field1 }</b></div>
          <div><b>{ lang['label_description'] }: </b>{ object.description }</div>
          <div><b>{ lang['label_map_quantity'] }: </b> { cardQuantity }</div>

        </div>
        <div className="production-rows-wrapper">
            <pre style={{ fontSize:'12px', margin:5, minHeight:50, position:'relative', overflow:'none'}}>
                <div className="row">
                    <div className="col-md-12">
                        <div style={{ height:20, columnWidth: 200 }}>
                            <div>
                            <span>Text</span>
                            <span>/ TH /</span>
                            <span>Rotation</span>
                            </div>
                            

                        </div> 
                        <div ref={ selection } className="text-selection" style={{ height:'auto', columnWidth:150 }}>
                        {
                            props.items.map( (item,i) => {
                                const rows = item.rows;
                                
                                return (
                                    rows.map( (prod,j) => {
                                        let combo = `${props.index}${j}`;
                                        return(
                                            <div key={j}>
                                                <span class={`copy-field ${ isSelected === true ? 'selected-text' : ''}`}>{prod.text}</span>
                                                <span>{ ' / '+prod.size+' / ' }</span>
                                                <span>{ prod.rotation+'\n'}</span>
                                            </div> 
                                            
                                        )
                                    })
                                    
                                );
                            })
                        }
                        </div>
                    </div>
                </div>
                <i className="fas fa-file-alt select" style={{ position:'absolute', top:-5, right:-5 }} onClick={ (e) => onSelectText(e) }/>
            </pre>
        </div>
      </div>
    );
  }


export const ProductionSortShopItem = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);

  return (
    <div className="hashedProd" style={{ marginBottom:40 }}>
      <div className="production-properties">
        <h5>Produkttyp: {lang[object.entity] } ({orderRow.field1}) </h5>
        <div><b>{ orderRow.field1 }</b> ({ lang[orderRow.entity] })</div>
        <div><img style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } alt={ object.imagepath }/></div>
        <div><b>{ lang['label_description'] }: </b>{ object.description }</div>
        <div><b>{ lang['label_quantity'] }:</b>{ orderRow.quantity }</div>
      </div>
    </div>
  );
}


export const ProductionSortAccessory = (props) => {

  const orderRow = props.orderRow;
  const object = JSON.parse(orderRow.object);

  return (
    <div className="hashedProd" style={{ marginBottom:40 }}>
      <div className="production-properties">
        <h5>Produkttyp: {lang[object.entity] } ({orderRow.field1}) </h5>
        <div><b>{ orderRow.field1 }</b> ({ lang[orderRow.entity] })</div>
        <div><img style={{ maxWidth: 200, maxHeight:200 }} src={ api.root + '' + object.imagepath } alt={ object.imagepath }/></div>
        <div><b>{ lang['label_description'] }: </b>{ object.description }</div>
        <div><b>{ lang['label_quantity'] }:</b>{ orderRow.quantity }</div>
      </div>
    </div>
  );
}

export const ProductionProductRowList = (props) => {
  const products = props.items;
    const hasQr = products.find( p => {
        if ( p.rows.find( r => r?.isQr === true ) )
            return true;
    }) || false;
    
  return (


            <React.Fragment>
            { products !== null &&
              <div>
                <div className="label"><b>Skyltrader</b> 
                {
                    hasQr !== false && (<span className="text-danger"> text i rött är QR-koder</span>) 
                }
                </div>
                <div className="production-rows-wrapper">
                   <ProductionProductTextrows items={products} quantity={props.quantity} />
                </div>
              </div>
            }
            </React.Fragment>

  );

};

export const ProductionProductTextrows = ( props ) => {
  let products = props.items;
//  let quantity = props.quantity;

  if( typeof products === 'undefined' || products.length <= 0 ) return '';

  return (
    <React.Fragment>
    <pre style={{ fontSize:'12px', margin:5, minHeight:50, position:'relative', overflow:'none'}}>

    {
      products.map( (item,i) => {
        const textrows = item.rows;
        const length = textrows.length-1;

        return (
          <div className="product-row" key={i}>
            {
              textrows.length> 0 &&
              textrows.map( ( row, j ) => {

                const text = typeof row === 'object' ? row.text : row;
                if( item.productType === 'CableMarker' ){
                  if( length === j ){

                    return <span key={j}>{ text }</span>
                  } else
                    return <span key={j}>{ text+'|'}</span>
                } else {
                  if( length === j ){
                    return <span className={ `${row?.isQr && 'text-danger' }` } key={j}>{ text || '\n' }</span>
                  } else
                    return <span className={ `${row?.isQr && 'text-danger' }` } key={j}>{ text+'\t'}</span>
                }

              })
            }
            </div>
        );
      })
    }
    <i className="fas fa-file-alt select" style={{ position:'absolute', top:-5, right:-5 }} onClick={ (e) => selectTextrows( e ) }/>
    </pre>
    </React.Fragment>
  );

}

const ProductionBlockContent = ( props ) => {
    const obj = { ...props.object };
    if( typeof obj.blocks === 'undefined' || obj.blocks.length <= 0) return '';

    const qrBlocks = obj.blocks.filter( b => Boolean( b?.isQRcode ) === true );
    const otherBlocks = obj.blocks.filter( b => Boolean( b?.isQRcode ) !== true );

    const productBlocks = [ ...otherBlocks, ...qrBlocks ];

    let content = productBlocks.map( (block,i) => {
    //  let measure = block.width +'x'+block.height;
        let left,right,top,bottom = 0;
        if(typeof block.position !== 'undefined'){
        left = block.position.left;
        right = block.position.right;
        top = block.position.top;
        bottom = block.position.bottom;
        }
        let bottomToCenter = round( (bottom + block.height/2),1, true );

        const BlockType = blockTypes[block.type];

        let measure;

        if( typeof block.diameter && block.diameter > 0 ){
        measure = <div><b>Diameter:</b> { round(block.diameter,1,true) } mm</div>;
        } else {
        measure = <div><b>Mått:</b> { round(block.width,1,true) }x{round(block.height,1,true)} mm</div>;
        }
        const blockParts = block.name.split('_');
        let blockName = lang[blockParts[0].toLowerCase()]+'_'+blockParts[1];

        blockName = block?.isQRcode ? ` ${lang.qr_code}` : blockName;
        
        return (
        <li key={i} className="production-block-content">
            <BlockType object={block} blockQty={ obj.blocks.length } >
            <h5> { blockName }</h5>
            {
                ( block.maximized !== true ) &&
                <div >
                <div><b>Position (V/H/U/N):</b> { round(left,1,true) } / { round(right,1,true)} / { round(top,1,true) } / {round(bottom,1,true)} (mm)</div>
                <div><b>Blockcenter från botten:</b> { bottomToCenter } mm</div>
                { (block.vertical_block_alignment !== null && block.vertical_block_alignment.length > 0) &&
                    <div><b>Vertikal blockjustering:</b> { lang.alignments[block.vertical_block_alignment] }</div>
                }
                { (block.horizontal_block_alignment !== null && block.horizontal_block_alignment.length > 0 ) &&
                    <div><b>Horisontell blockjustering:</b> { lang.alignments[block.horizontal_block_alignment] }</div>
                }
                { measure }

                </div>
            }
            </BlockType>
        </li>
        )
    });

    return (
        <ul className="production-block-list">
        { ( obj.product_type === 'Rectangular' || obj.product_type === "ValveTag" || obj.product_type === "EmergencyStop" || obj.product_type === "WireMarker" ) && content }
        { obj.product_type.indexOf('Bib') > -1 && displayBibContent( obj ) }
        { obj.product_type === "CableMarkerStainless" && displayCableMarkerStainlessBlock( obj ) }
        <li style={{ clear:'both', listStyleType: 'none' }} />
        </ul>

    );

};


function displayCableMarkerStainlessBlock( obj ){
  if( typeof obj.blocks === 'undefined' || obj.blocks.length <= 0) return '';

  let content = obj.blocks.map( (block,i) => {
      return (
        <ul className="production-block-list">
          <li key={i} className="production-block-content">
            <TextProductionView object={block} blockQty={ obj.blocks.length } productType={ obj.product_type } />
          </li>
        </ul>
      )
  });
  return content;
}

function displayBibContent( obj ){
  const blocks = [ ...obj.blocks ];
  let dia = 0;
  if( typeof blocks !== 'undefined' && blocks.length > 0 ){

    for(let i in blocks ){
      if(blocks[i].type === "HoleBlock" ){
        dia = blocks[i].diameter;
      }
    }
  }

  return ( <div><b>Håldiameter:</b> { dia } mm</div> );
}

function selectTextrows( e ) {
  let text = $(e.target).closest('pre')[0];
  let doc = document;
//  let text = doc.getElementById(elementId);

  let range;
  let selection;
  if (doc.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(text);
    range.select();

  } else if (window.getSelection) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(text);
    selection.removeAllRanges();
    selection.addRange(range);

  }
}

